import React from "react"
import { Helmet } from "react-helmet"
import LayoutMain from "../components/layouts/LayoutMain"
import "../styles/conditionsOfUse.scss"


const ConditionsOfUsePage = (props) => (

      <LayoutMain>
        <Helmet>
          <title>Structured Marketing Conditions of Use</title>
          <meta
            name="description"
            content="Structured Marketing conditions of use"
          />
          <bodyAttributes
            className="theme1"
          />
        </Helmet>


        <div className="container-conditions-use padding-standard bgColorLight">

          <div className="conditionsUse">

            <h1>CONDITIONS OF USE</h1>
            <p>Last Updated: January 15, 2021</p>

            <p>Structured Marketing LLC and/or their affiliates ("Structured Marketing") provide features and other products and services to you when you visit Structured Marketing.com (the "Structured Marketing Website"), use Structured Marketing devices, products, or services, use Structured Marketing applications, or use software provided by Structured Marketing in connection with any of the foregoing (collectively "Structured Marketing Services"). Structured Marketing provides the Structured Marketing Services to you subject to the conditions set out on this page.</p>


            <h2>PRIVACY</h2>
            <p>Please see our Privacy Notice on the Structured Marketing Website to understand how we collect and process your personal information through Structured Marketing Services.</p>


            <h2>ELECTRONIC COMMUNICATIONS</h2>
            <p>When you use Structured Marketing Services, or send e-mails, text messages, and other communications from your device to us, you may be communicating with us electronically. You consent to receive communications from us electronically, such as e-mails, texts, mobile push notices, or notices and messages through Structured Marketing Services, and you can retain copies of these communications for your records. You agree that all agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.</p>


            <h2>DATA RECEPTION AND TRANSMISSION</h2>
            <p>Some Structured Marketing services will receive and transmit Structured Marketing information that is in proximity to a physical device with Structured Marketing services installed.  The physical device will receive data from a Structured Marketing device and transmit this data to the Structured Marketing servers.  Any encrypted data will pass through without decryption. Structured Marketing is not responsible for any costs due to bandwidth or data reception and transmission.</p>


            <h2>COPYRIGHT</h2>
            <p>All content included in or made available through any of the Structured Marketing Services, such as text, graphics, logos, and software is the property of Structured Marketing or its content suppliers and protected by United States and international copyright laws. The compilation of all content included in or made available through any of the Structured Marketing Services is the exclusive property of Structured Marketing and protected by U.S. and international copyright laws.</p>


            <h2>TRADEMARKS</h2>
            <p>Graphics, logos, button icons, and scripts, included in or made available through any of the Structured Marketing Services are trademarks or trade dress of Structured Marketing in the U.S. and other countries. Structured Marketing's trademarks and trade dress may not be used in connection with any product or service that is not Structured Marketing 's, in any manner that is likely to cause confusion among customers, or in any manner that disparages or discredits Structured Marketing. All other trademarks not owned by Structured Marketing that appear in any of the Structured Marketing Services are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by Structured Marketing.</p>


            <h2>PATENTS</h2>
            <p>One or more patents owned by Structured Marketing apply to the Structured Marketing Services and to the features and services accessible via the Structured Marketing Services. Portions of the Structured Marketing Services operate under license of one or more patents.</p>

            <h2>FEEDBACK</h2>
            <p>You may provide written or verbal feedback, suggestions, comments, or input to us relating to Structured Marketing Services, Software Apps, Structured Marketing Products, User Devices, or other opportunities for our existing or future activities ("Feedback"). By providing Feedback to us, you grant to us the worldwide, nonexclusive, unrestricted, perpetual, irrevocable (on any basis whatsoever), royalty free right for us to use such Feedback in any way we determine, including through third parties, without any obligation to you for compensation, attribution, accounting or otherwise. You will only provide to us Feedback for which you have the right to grant to us the rights listed in the preceding sentence.</p>

            <p>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</p>
            <p>THE Structured Marketing SERVICES AND ALL INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) AND OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE Structured Marketing SERVICES ARE PROVIDED BY Structured Marketing ON AN "AS IS" AND "AS AVAILABLE" BASIS, UNLESS OTHERWISE SPECIFIED IN WRITING. Structured Marketing MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THE Structured Marketing SERVICES, OR THE INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE Structured Marketing SERVICES, UNLESS OTHERWISE SPECIFIED IN WRITING. YOU EXPRESSLY AGREE THAT YOUR USE OF THE Structured Marketing SERVICES IS AT YOUR SOLE RISK.</p>

            <p>TO THE FULL EXTENT PERMISSIBLE BY LAW, Structured Marketing DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. Structured Marketing DOES NOT WARRANT THAT THE Structured Marketing SERVICES, INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE Structured Marketing SERVICES, Structured Marketing'S SERVERS OR ELECTRONIC COMMUNICATIONS SENT FROM Structured Marketing ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. TO THE FULL EXTENT PERMISSIBLE BY LAW, Structured Marketing WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF ANY Structured Marketing SERVICE, OR FROM ANY INFORMATION, CONTENT, MATERIALS, PRODUCTS (INCLUDING SOFTWARE) OR OTHER SERVICES INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH ANY OF THE Structured Marketing SERVICES, INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL DAMAGES, UNLESS OTHERWISE SPECIFIED IN WRITING.</p>


            <h2>DISPUTES</h2>
            <p>Any dispute or claim relating in any way to your use of any of the Structured Marketing Services, or to any products or services sold or distributed by Structured Marketing will be resolved by binding arbitration, rather than in court, except that you may assert claims in small claims court if your claims qualify. The Federal Arbitration Act and federal arbitration law apply to this agreement.</p>

            <p>There is no judge or jury in arbitration, and court review of an arbitration award is limited. However, an arbitrator can award on an individual basis the same damages and relief as a court (including injunctive and declaratory relief or statutory damages) and must follow the terms of these Conditions of Use as a court would.</p>

            <p>The arbitration will be conducted by the American Arbitration Association (AAA) under its rules, including the AAA's Supplementary Procedures for Consumer-Related Disputes. The AAA's rules are available at www.adr.org or by calling 1-800-778-7879. Payment of all filing, administration and arbitrator fees will be governed by the AAA's rules.</p>

            <p>We each agree that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated or representative action. If for any reason a claim proceeds in court rather than in arbitration, we each waive any right to a jury trial. We also both agree that you or we may bring suit in court to enjoin infringement or other misuse of intellectual property rights.</p>


            <h2>APPLICABLE LAW</h2>
            <p>By using any Structured Marketing Services, you agree that the Federal Arbitration Act, applicable federal law, and the laws of the state of California, without regard to principles of conflict of laws, will govern these Conditions of Use and any dispute of any sort that might arise between you and Structured Marketing.</p>

            <p>The Structured Marketing Services are controlled, operated and administered by Structured Marketing from offices within the USA. If you access Structured Marketing Services from a location outside the USA, you are responsible for compliance with all local laws. You agree that you will not use the content accessed through the Structured Marketing Services in any country or in any manner prohibited by any applicable laws, restrictions or regulations.</p>


            <h2>SITE POLICIES, MODIFICATION, AND SEVERABILITY</h2>
            <p>Please review our other policies posted on the Structured Marketing Website. These policies also govern your use of Structured Marketing Services. We reserve the right to make changes to our Structured Marketing Website, policies, and these Conditions of Use at any time. If any of these conditions shall be deemed invalid, void, or for any reason unenforceable, that condition shall be deemed severable and shall not affect the validity and enforceability of any remaining condition.</p>


            <h2>CONTACT INFORMATION</h2>
            <p>Structured Marketing LLC<br/>
            4304 Endcliffe Drive<br/>
            Austin, TX 78731</p>

            <p>Email Address: hello@structured-marketing.com</p>

          </div>

        </div>


      </LayoutMain>
    )

export default ConditionsOfUsePage



